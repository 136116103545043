//import map icons
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import './assets/images/*.(svg|png)';
import type { IclFeedItem, PopupConfig } from './types';

const googleMapManager = () => {

    let googleMap: google.maps.Map;
    let markerClusterer: MarkerClusterer;
    let mapBounds: google.maps.LatLngBounds;
    let popupConfig: PopupConfig;
    let centerMarker: google.maps.Marker;
    let boundsFit: boolean = false;
    let clinicsData: IclFeedItem[] = [];
    let markers: google.maps.Marker[] = [];
    let refineSearch: boolean = true;

    const icons = {
        centerPoint: '/content/dam/juvederm-ous/common/icons/centerPointIcon.png',
        locationPin: '/content/dam/juvederm-ous/common/icons/locationPinIcon.png',
        clusterLocation: '/content/dam/juvederm-ous/common/icons/clusterIcon.png',
    };

    //Clean markers and bounds
    const resetMap = (): void => {
        markers = [];
        markerClusterer.clearMarkers();
        mapBounds = new google.maps.LatLngBounds();
    }
    
    //set blue marker on the center of the map
    const setCenter = (latitude: number, longitude: number): void => {
        const center = new google.maps.LatLng(latitude, longitude);
        centerMarker = new google.maps.Marker({
            map: googleMap, 
            position: center,
            icon: icons.centerPoint,
            zIndex: 1100,
        });
        mapBounds.extend(center);
        fitBoundsKeepingCenter();
    }

    const getQueryParamFromUrl = (paramName: string): string => {
        const parameterName = paramName;
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop: string) => searchParams.get(prop),
        });
  
        return params[parameterName];
    }

    const fitBoundsKeepingCenter = (): void => {

        if (!centerMarker) {
            return;
        }

        const center = centerMarker.getPosition()!;

        if (!boundsFit && markers.length > 0) {

            // extend map to cover all markers
            markers.forEach((marker) =>  mapBounds.extend(marker.getPosition()!));

            // Calculate the bounding box that contains all markers while keeping the
            // centerMarker at the center of the map

            let swLat = mapBounds.getSouthWest().lat();
            let swLng = mapBounds.getSouthWest().lng();
            let neLat = mapBounds.getNorthEast().lat();
            let neLng = mapBounds.getNorthEast().lng();

            const latDelta = Math.max(Math.abs(center.lat() - swLat), Math.abs(neLat - center.lat()));
            const lngDelta = Math.max(Math.abs(center.lng() - neLng), Math.abs(swLng - center.lng()));
        
            //Calculating new SW
            swLat = center.lat() - latDelta;
            swLng = center.lng() - lngDelta;

            mapBounds.extend(new google.maps.LatLng(swLat, swLng));
        
            //Calculating ne NE
            neLat = center.lat() + latDelta;
            neLng = center.lng() + lngDelta;
            mapBounds.extend(new google.maps.LatLng(neLat, neLng));

            googleMap.fitBounds(mapBounds);
            boundsFit = true;

        }
        
        googleMap.setCenter(center);

    }

    const manageMapDragEvent = (): void => {
        
        if (refineSearch) {
            centerMarker.setMap(null);         
            const center = googleMap.getCenter();
            const latitude = center!.lat();
            const longitude = center!.lng();

            window.Bus.emit('emu-icl-feed:referencePointChanged', {latitude, longitude});
            clinicsData = [];
        }
    }

    const returnFixedUrl = (url: string): string => {
        const isProtocol=  ['http://', 'https://'].some(protocol => url.startsWith(protocol));
        return (isProtocol) ? url : `//${url}`;
    }

    const googleMapsMarkerPopupTemplate = (clinic: IclFeedItem, popupConfig: PopupConfig): string => {
        const countryCode = getQueryParamFromUrl('country');
        const countryParam = countryCode ? `&country=${countryCode}` : '';
        const address = clinic.clinic_address?.street || clinic.address;
        const city = clinic.clinic_address?.city || clinic.city;
        const zipCode = clinic.clinic_address?.zip_code || clinic.postalCode;
        const website = clinic.website_url || clinic.website;
        const contact = clinic.clinic_contact || clinic.phone;
        const long = clinic.longi || clinic.long;
        const clinicId = clinic.clinicId || clinic.accountId;

        return `
            <div class="clinic-popup">
                <div class="clinic-popup__heading">${clinic.clinicName}</div>
                    <div class="clinic-popup__description">
                        ${clinic.distance}${popupConfig.unitSystem} · ${address}, ${city}, ${zipCode}
                    </div>
                    <div class="clinic-popup__buttons-container">
                        ${website ? `<a class="clinic-popup__link clinic-popup__link-website" href="${returnFixedUrl(website)}" target="_blank" tabindex="0">${popupConfig.websiteLabel}</a>` : ''}
                        ${contact ? `<a class="clinic-popup__link clinic-popup__link-phone" href="tel:${contact}">${popupConfig.phoneLabel}</a>` : ''}            
                        <a class="clinic-popup__link clinic-popup__link-clinic" href="${popupConfig.clinicDetailPath}?location=${clinic.lat}%2C${long}&clinic=${clinicId}${countryParam}" aria-label="Show the clinic">
                            <img src="${popupConfig.goToClinicIcon}" alt="${popupConfig.goToClinicTitleLabel}">
                        </a>
                    </div>
                </div>
            </div>
        `;
    }

    const setInfowindowEventListener = (infowindow: google.maps.InfoWindow): void => {
        let websiteAnchorClickHandler: ((this: GlobalEventHandlers, ev: MouseEvent) => any) | null;

        google.maps.event.addListener(infowindow, 'domready', (): void => {
            const websiteAnchor = document.querySelector('.clinic-popup__link-website') as HTMLAnchorElement;

            if (websiteAnchor && websiteAnchorClickHandler) {
                websiteAnchor.removeEventListener('click', websiteAnchorClickHandler);
            }

            websiteAnchorClickHandler = (e: MouseEvent): void => {
                e.preventDefault();
                const websiteAnchorHref = websiteAnchor.href;
                const modalTrigger = document.getElementById('leaving-website-modal') as HTMLButtonElement;
                modalTrigger?.click();

                queueMicrotask(() => {
                    const modalCtaConfirm = document.querySelector('.modal__cta--confirm') as HTMLAnchorElement;
                    modalCtaConfirm.href = websiteAnchorHref;
                });
            };

            if (websiteAnchor && popupConfig?.showLeavingWebsiteModal) {
                websiteAnchor.addEventListener('click', websiteAnchorClickHandler);
            }
        });
    }
    
    const setMarkers = (): void => {
        let prevInfoWindow: google.maps.InfoWindow;

        resetMap();

        //creating markers and elements related to them
        clinicsData.forEach((clinic) => {

            let lat = clinic.lat;
            let lng = clinic.longi || clinic.long;

            //if two markers share the exact same location, google maps only displays one, so we add a little displacement
            const dup = markers.find((m: google.maps.Marker) => m.getPosition()?.lat() == lat && m.getPosition()?.lng() == lng);
            if (dup) {
                lat = lat + 0.00001;
                lng = lng + 0.00001;
            }

            //creating a popup for a marker
            const infowindow = new google.maps.InfoWindow({
                content: googleMapsMarkerPopupTemplate(clinic, popupConfig),
                ariaLabel: clinic.clinicName,
            });

            setInfowindowEventListener(infowindow);

            //creating a marker/pin
            const myLatLang = new google.maps.LatLng(lat,lng);
            const markerInstance = new google.maps.Marker({
                position: myLatLang,
                icon: icons.locationPin,
            });

            //after user click on marker/pin
            markerInstance.addListener("click", () => {
                //on the mobile view, after clicking on the marker, a popup with information about the clinic will be displayed
                if (window.innerWidth < 768) {

                    if (prevInfoWindow) {
                        prevInfoWindow.close();
                    }

                    prevInfoWindow = infowindow;
                    infowindow.open({
                        anchor: markerInstance,
                        map: googleMap
                    });

                //otherwise, after clicking the marker, we emit the client's id to ICL Feed component (clinics list)
                } else {
                    const clinidId = clinic.clinicId || clinic.accountId;
                    window.Bus.emit('emu-icl-feed:markerSelected', { clinicId: clinidId });
                }
            });

            markers.push(markerInstance);

        });

        markerClusterer.addMarkers(markers);
        fitBoundsKeepingCenter();
        
    }

    const initializeMap = (mapInstance: google.maps.Map): void => {

        //google map configuration
        const mapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
            },
        };     

        googleMap = mapInstance;   
        googleMap.setOptions(mapOptions);
        mapBounds = new google.maps.LatLngBounds();

        const renderer = {
            //https://googlemaps.github.io/js-markerclusterer/classes/DefaultRenderer.html
            render: ({ count, position }) =>
                new google.maps.Marker({
                    position,
                    icon: {
                        url: icons.clusterLocation,
                        scaledSize: new google.maps.Size(45, 45),
                    },
                    label: {
                        text: String(count),
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold"
                    },
                    zIndex: 1000 + count,
            })
        };

        markerClusterer = new MarkerClusterer({ map: googleMap, renderer}); 
    }

    const registerEvents = (): void => {
        window.Bus.on('emu-google-map:init', ({instance}) => {
    
            initializeMap(instance);
            
            //Reacts when user has finished moving the map
            google.maps.event.addListener(googleMap, "dragend", manageMapDragEvent);

            window.Bus.on('emu-icl-feed:referencePointResolved', ({ latitude, longitude }) => {
                setCenter(latitude, longitude);       
            });

            window.Bus.on('emu-icl-feed:config', (config: PopupConfig) => { 
                popupConfig = config;
            });

            window.Bus.on('emu-icl-feed:clinicParsed', (clinics: IclFeedItem[]) => { 
                clinicsData = clinicsData.concat(clinics as IclFeedItem[]);
                 setMarkers();
            });
    
            window.Bus.on('emu-clinics-list:checkboxChange', (checkboxStatus: boolean) => {
                refineSearch = checkboxStatus;
            });
        });
    }

    const init = (): void => {
        registerEvents();
    }

    init();
}

/**
 * The clinic Result is provied by ICL Feed component. 
 * However, in the mobile view, this element must be available in both tabs, list and map. 
 * So we need to create a copy of the item and update it.
 * 
 * IMPORTANT: we must remember that we perform operations on elements that are created dynamically
 *            by core components from templates and their content changes depending on the user's action.
 */
const clinicsResults = () => {
    let clinicsContainer: HTMLElement;
    let clinicsMap: HTMLElement;
    let clonedElement: HTMLElement;

    const selectors = {
        clinicsResults: 'clinics__results',
        clinicsContainer: 'icl-feed-container',
        mapContainer: 'tabpanel__container-map',
        clinicsMap: 'googlemaps',
    };

    const constructor = () => {
        clinicsContainer = document.querySelector(`.${selectors.clinicsContainer}`)!;
        clinicsMap = document.querySelector(`.${selectors.clinicsMap}`)!;
    }

    const updateClonedClinicResult = (itemsCount: number,locationName: string) => {
        const clonedClinicResultsNumber = document.querySelector(`.js-${selectors.clinicsResults}--clone .${selectors.clinicsResults}--number`);
        const clonedClinicResultsLocation = document.querySelector(`.js-${selectors.clinicsResults}--clone .${selectors.clinicsResults}--location`);

        if (clonedClinicResultsNumber && clonedClinicResultsLocation) {
            clonedClinicResultsNumber.innerHTML = itemsCount.toString();
            clonedClinicResultsLocation.innerHTML = locationName;
        }
    }
    const cloneClinicResults = () => {
        const clinicsResults = document.querySelector(`.js-${selectors.clinicsResults}`)!;

        if (clinicsResults) {
            clonedElement = clinicsResults.cloneNode(true) as HTMLElement;
            clonedElement.classList.add(`js-${selectors.clinicsResults}--clone`);
            clinicsMap.before(clonedElement); 
        }
    }

    const registerEvents = () => {
            
        window.Bus.on('emu-icl-feed:clinicResultsChanged', ({itemsCount,locationName}) => {
            updateClonedClinicResult(itemsCount,locationName);
        });

        //we need to be sure that the list of clinics and clinics Resulta has already been rendered by core component
        clinicsContainer.addEventListener(
            'DOMNodeInserted',
            () => cloneClinicResults(),
            { once: true }
        );            
    }

    const init = () => {
        constructor();

        if (clinicsContainer) {
            registerEvents();
        }
    }

    init();
};

/**
 * Refine search button which is an integral part of the map
 * and is supposed to move with it must be defined as an AEM component. 
 * So we need to move it to the container with the map.
 */
const moveRefineSearchCheckbox = () => {
    const selectors = {
        target: 'googlemaps',
        checkboxContainer: 'options',
        checkbox: 'cmp-form-options__field--checkbox',
    };

    let checkbox: HTMLInputElement;
    let target: HTMLElement;
    let checkboxContainer: HTMLElement;
    let defaultCheckedAttribute: boolean = true;

    const constructor = () => {
        checkboxContainer = document.querySelector(`.${selectors.checkboxContainer}`)!;
        target = document.querySelector(`.${selectors.target}`)!;
        checkbox = document.querySelector(`.${selectors.checkbox}`)!;
        if (checkbox) checkbox.checked = defaultCheckedAttribute;
    }

    const moveCheckbox = () => {
        checkboxContainer.remove();
        target.append(checkboxContainer);
    }

    const emitCheckboxStatus = (status: boolean) => {
        window.Bus.emit('emu-clinics-list:checkboxChange', status);
    }

    const registerEvents = () => {
        checkbox.addEventListener('change', (event) => {
            let target = event.target as HTMLInputElement;
            emitCheckboxStatus(target.checked);
        });
    }

    const init = () => {
        constructor();

        if (checkboxContainer) {
            moveCheckbox();
            registerEvents();
        }        
        
    }

    init();
}

/**
 * we provide the possibility of sticking (position fixed) and unsticking the map container at the right moment.
 */
const stickyMapManager = () => {
    let mapContainer: HTMLElement;

    const selectors = {
        panelsContainer: "icl-feed-container",
        mapContainer: "googlemaps",
        footerContainer: document.querySelector('#juvederm-phase1') ? 'footer .upper_section' : 'footer-info',
    };

    const constructor = () => {
        mapContainer = document.querySelector(`.${selectors.mapContainer}`)!;
    }

    const toogleClass = (payload) => {
        mapContainer.classList.toggle("not-sticky", payload[0].isIntersecting);
    }

    const calulateScrollPosition = () => {
        const footer = document.querySelector(`.${selectors.footerContainer}`) as Element;
        const ob = new IntersectionObserver(toogleClass);
        ob.observe(footer);
    }

    const init = () => {
        constructor();

        if (mapContainer) {
            calulateScrollPosition();
        }
    }

    init();
}

/**
 * Tabs CORE component does not provide the possibility of adding icons next to tab names, so we have to do it from JS level.
 */
const addIconsToTabs = () => {
    let tabsComponent: HTMLElement;

    /**
     * Tab component does't allow to add a unique css class name for individual tabs 
     * that why we have to assume there will always be two tabs.
     * :first-child is a map
     * :last-child is a list
     */
    const selectors = {
        tabsComponent: '.icl-tabs',
        dataList: '.aaaem-tabs__tab:last-child > a',
        dataMap: '.aaaem-tabs__tab:first-child > a',
        anchor: '.aaaem-tabs__tab > a',
        tabpanel: 'aaaem-tabs__tabpanel',
    }

    const constructor = () => {
        tabsComponent = document.querySelector(selectors.tabsComponent)!;
    }

    const iconTemplate = (iconName: string) => {
        return `
            <svg aria-hidden="true" viewBox="0 0 256 256" role="img" class="tab-icon">
                <use xlink:href="/content/dam/juvederm-ous/common/icons/sprite.svg#${iconName}"></use>
            </svg>
        `;
    }

    const insertIcons = () => {
        const tabListAnchor = document.querySelector(selectors.dataList)!;
        const tabMapAnchor = document.querySelector(selectors.dataMap)!;
        tabListAnchor.insertAdjacentHTML('afterbegin', iconTemplate('List'));
        tabMapAnchor.insertAdjacentHTML('afterbegin', iconTemplate('Map'));
    }

    const init = () => {
        constructor();

        if (tabsComponent) {
            insertIcons();
        }
    }

    init();
}

/**
 * If you want to see the modal on the mobile view in both tabs, 
 * we must to move it higher in the html structure. 
 * Modal is an integral part of the ICL feed component.
 */
const moveModal = () => {
    const selectors = {
        modal: '.iclfeed .modal',
        iclTabs: '.icl-tabs',
        modalTrigger: ['#message-modal', '#leaving-website-modal']
    }

    const removeElement = (selector: string) => {
        queueMicrotask(() => {
            const elements = document.querySelectorAll(selector);

            elements.forEach((element) => {
                if (element) {
                    element.remove();
                }
            });
        });
    }

    const moveModal = () => {
        const modalElements = document.querySelectorAll(selectors.modal)!;
        const iclTabsElement = document.querySelector(selectors.iclTabs)!;

        modalElements.forEach((modalElement) => {
            if (modalElement && iclTabsElement) {
                iclTabsElement.append(modalElement);
            }
        });
    }

    const registerEvents = () => {
        selectors.modalTrigger.forEach((trigger) => {
            const modalTrigger = document.querySelector(trigger)!;

            if (modalTrigger) {
                modalTrigger.addEventListener('click', () => {
                    removeElement(selectors.modal);
                });
            }
        });
    }

    const init = () => {
        moveModal();
        registerEvents();
    }

    init();
}

export const clinicsInit = () => {
    googleMapManager();
    addIconsToTabs();
    stickyMapManager();
    clinicsResults();
    moveRefineSearchCheckbox();
    moveModal();
}