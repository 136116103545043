import VimeoPlayer from '@vimeo/player';

// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './pages/**/*.scss';

// import favicon here.
const faviconLink = document.querySelector(
  "link[rel~='icon']"
) as HTMLLinkElement;
faviconLink.href = '/content/dam/juvederm-ous/favicon.ico';

import { headerInit } from './components/header/header';
import { teaserInit } from './components/teaser/teaser';
import { findClinicFormInit } from './components/findClinicForm/findClinicForm';
import { searchLocationInit } from './components/searchLocation/searchLocation';
import { treatmentAreasInit } from './components/treatmentAreas/treatmentAreas';
import { topicMenuInit } from './components/topicMenu/topicMenu';
import { mediaTextInit } from './components/mediaText/mediaText';
import { clinicsInit } from './pages/clinics/clinics';
import { phase1Init } from './phase1-main';
import { addDatalayerEvents } from './js/helpers/addDatalayerEvents';
import { consultationWizard } from './components/consultationWizard/consultationWizard';
import { under18HeaderOverrides } from './components/header/under18HeaderOverrides';

declare global {
  interface Window {
    dataLayer: any;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

// when modal is closed and if it has an embed iframes in it, pausing the video as soon as the modal is closed
const handleModalClose = () => {
  window.Bus.on('emu-modal:close', ({ id }) => {
    const modal = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
    const iframes = modal?.querySelectorAll(
      '.emu-embed iframe'
    ) as NodeListOf<HTMLIFrameElement>;
    if (iframes?.length && VimeoPlayer) {
      iframes.forEach(iframe => {
        const player = new VimeoPlayer(iframe);
        player?.pause?.();
      });
    }
  });
};

const init = () => {
  window.dataLayer = window.dataLayer ? window.dataLayer : [];
  if (window.Bus) {
    handleModalClose();
  }

  headerInit();
  teaserInit();
  findClinicFormInit();
  searchLocationInit();
  topicMenuInit();
  mediaTextInit();
  clinicsInit();
  consultationWizard();
  under18HeaderOverrides();
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    init();
    phase1Init();
  });
  window.addEventListener('load', () => {
    treatmentAreasInit();
    addDatalayerEvents();
  });
} else {
  init();
  phase1Init();
  window.addEventListener('load', () => {
    treatmentAreasInit();
    addDatalayerEvents();
  });
}
